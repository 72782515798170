<template>
  <base-modal
    overlay-class="bg-gray-900 opacity-60"
    popup-class="bg-white text-left overflow-hidden shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
  >
    <template #modalHeader>
      <header class="px-2.5 py-2 bg-custom-gray-2">
        <span class="text-primary-red text-sm">
          Confirm Delete
        </span>
      </header>
    </template>
    <template #modalBody>
      <div class="px-2 py-3">
        <div class="font-sm text-center mb-2">
          Are you sure to delete this search?
        </div>
        <div class="text-center">
          <base-button
            class="mt-2 mb-2 mr-4"
            variant="btn-primary"
            text="Yes"
            @click="emitAcceptedEvent()"
          />
          <base-button
            class="mt-2 mb-2"
            variant="btn-gray"
            text="No"
            @click="emitRejectedEvent()"
          />
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
    name: 'ConfirmDeleteModal',

    components: {
        BaseModal: defineAsyncComponent(() => import('@/components/generic-components/BaseModal.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue'))
    },

    emits: [
        'accepted',
        'rejected'
    ],

    setup (props, { emit }) {
        const emitAcceptedEvent = () => {
            emit('accepted');
        };
        const emitRejectedEvent = () => {
            emit('rejected');
        };

        return {
            emitAcceptedEvent,
            emitRejectedEvent
        };
    }
};
</script>
